<template>
  <div>
    <v-row class="pl-2 pr-2" >
      <v-col md="6"><form-ads /></v-col>
      <v-col md="6"><preview-ads></preview-ads></v-col>
    </v-row>
  </div>
</template>

<script>
import PreviewAds from '../preview/preview'
import FormAds from './form'
    export default {
        components:{FormAds, PreviewAds}
        
    }
</script>

<style lang="scss" scoped>

</style>