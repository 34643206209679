<template>
  <v-card :loading="loadingSave">
    <v-card-title>Adicionar Anúncio</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-select
          :items="['Imagem', 'Vídeo']"
          label="Tipo de mídia"
          outlined
          v-model="$store.state.ads.newAds.type"
        ></v-select>

        <v-row>
        <v-col md="4">
        <v-switch
          v-model="$store.state.ads.newAds.status"
          inset
          :label="
            'Status ' + ($store.state.ads.newAds.status ? 'ativo' : 'inativo')
          "
        />
        </v-col>
        <v-col md="4">
        <v-switch
          v-model="$store.state.ads.newAds.showQr"
          inset
          :label="'QRcode'"
        />
        </v-col>
        <v-col md="4" v-if="$store.state.ads.newAds.type == 'Imagem'">
            <v-text-field style="margin-top: 20px"
              hide-details=""
              v-model="$store.state.ads.newAds.time"
              label="Tempo de exibição"
              dense
              type="number"
              :rules="[
                (v) =>
                  v > 0 || 'Tempo em que a notícia ficará na tela do totem',
              ]"
            ></v-text-field> Segundos
          </v-col>
       </v-row>
        <v-row><picker></picker></v-row>

        <v-text-field
          outlined
          :title="'Nome de identificação deste anúncio'"
          label="Nome"
          v-model="$store.state.ads.newAds.name"
          :rules="validateName"
          required
          small
        ></v-text-field>

        

        <v-text-field
          outlined
          :title="'Cole aqui o link de para o qr-code'"
          label="Cole aqui o link de para o qr-code"
          v-model="$store.state.ads.newAds.qrcode"
          
          required
          small
        ></v-text-field>

        <v-row v-if="!!$store.state.ads.newAds.type">
          <v-col>
            <v-text-field
              outlined
              :readonly="readonlyFile"
              :title="'Cole aqui o link de mídia ou faça upload clicando no botão ao lado'"
              label="Cole aqui o link de mídia ou faça upload"
              v-model="$store.state.ads.newAds.link"
              :rules="validateLink"
              required
              small
            ></v-text-field>
          </v-col>
          <v-col>
            <v-file-input
              class="file"
              label="Carregar mídia"
              filled
              prepend-icon="mdi-camera"
              :accept="
                $store.state.ads.newAds.type == 'Imagem' ? 'image/*' : 'video/*'
              "
              @change="changeFile"
              :loading="loadingFile"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-card-actions v-if="!!$store.state.ads.newAds.type">
          <v-btn
            color="error"
            class="mr-4"
            @click="$router.push('/advertisements/list')"
            >cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            :loading="loadingSave"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { createAds } from "../../../services/ads";
// import { createNews, updateNews } from "../../services/news";
//import sendFile from "../../../services/sendFile";
import { sendImageAds, sendVideoAds, getFile } from "../../../services/repository";
import picker from "./datePicker";
// import { changeCrowller } from "../../services/crowller";
const timeout = time => new Promise(resolve => setTimeout(resolve, time || 3000))
export default {
  components: { picker },
  data: () => ({
    ticket: 0,
    loadingSave: false,
    loadingFile: false,
    readonlyFile: false,
    link: "",
    valid: false,
    validateLink: [
      (v) =>
        (v || []).length > 0 || "Link de mídia para este anúncio é obrigatório",
    ],
    validateQr: [
      (v) =>
        (v || []).length > 0 ||
        "Link para o QRcode é obrigatório, formato: https://meuAnuncio.com.br",
    ],
    validateName: [
      (v) =>
        (v || []).length > 0 ||
        "O nome é obrigatório",
    ],
  }),
  watch: {
    loadingFile(value){
      this.$store.state.loading = value
    }
  },
  methods: {
    async loopGetFile(){
      await timeout()
      const data = await getFile(this.ticket).catch(()=>false)
      if(!data){
        this.loadingFile = false;
        this.$store.commit(
            "failed",
            "Falha ao carregar arquivo, verfica se o arquivo corresponde a uma imagem ou vídeo ou você pode ter perdido acesso à cloudnary"
          );
      }
      if(data && data.url){
        this.loadingFile = false;
        this.readonlyFile = true;
        this.$store.commit("success", "URL da mídia gerada com sucesso");
        return this.$store.state.ads.newAds.link = data.url
      }
      this.loopGetFile()
    },
    sendVideo(data) {
      sendVideoAds(data)
        .then((status) => {
          this.ticket = status.ticket
          return this.loopGetFile()
        })
        .catch(() => {
          this.loadingFile = false;
          this.$store.commit(
            "failed",
            "Falha ao carregar arquivo, verfica se o arquivo corresponde a uma imagem ou vídeo ou você pode ter perdido acesso à cloudnary"
          );
        });
    },
    sendImage(data) {
      sendImageAds(data)
        .then((url) => {
          //const urlMount = url.split('upload/')
          this.$store.state.ads.newAds.link = url.url; //urlMount[0] + 'upload/c_fill,g_auto,h_1920,w_1080/' + urlMount[1]
          this.loadingFile = false;
          this.$store.commit("success", "URL da mídia gerada com sucesso");
          this.readonlyFile = true;
        })
        .catch(() => {
          this.loadingFile = false;
          this.$store.commit(
            "failed",
            "Falha ao carregar arquivo, verfica se o arquivo corresponde a uma imagem ou vídeo ou você pode ter perdido acesso à cloudnary"
          );
        });
    },
    async changeFile(data) {
      if (!data) return (this.$store.state.ads.newAds.link = "");
      this.loadingFile = true;
      if (this.$store.state.ads.newAds.type == "Imagem") {
        this.sendImage(data);
      } else {
        this.sendVideo(data);
      }
    },
    async save() {
      const isValid = this.$refs.form.validate();
      if (!isValid) return;
      this.loadingSave = true;
      this.$store.state.ads.newAds.user = this.$store.state.user;
      createAds(this.$store.state.ads.newAds)
        .then((data) => {
          data.startDate = new Date(data.startDate).toLocaleDateString();
          data.endDate = new Date(data.endDate).toLocaleDateString();
          this.$store.state.ads.data.push(data);
          this.loadingSave = false;
          this.$store.commit("success", "Anúncio cadastrado com sucesso");
          this.$router.push("/advertisements/list");
        })
        .catch((err) => {
          this.$store.commit("failed", err);
          this.loadingSave = false;
        });
    },
    validUrl() {},
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  beforeDestroy() {
    this.$store.commit("reset");
  },
};
</script>

<style scoped>
.file >>> * {
  cursor: pointer;
}
</style>