<template>
  <v-card>
      <v-card-text>

    <div class="prev">
      <img
        :src="$store.state.ads[$route.name].link"
        alt=""
        v-if="$store.state.ads[$route.name].type == 'Imagem'"
      />
      <video
        v-else-if="$store.state.ads[$route.name].type == 'Vídeo' && $store.state.ads[$route.name].link"
        :src="$store.state.ads[$route.name].link"
        autoplay
        muted
      ></video>
      <div v-if="showQr" class='imgPrev'>
        <div class="leiamais" >LEIA MAIS</div>
      <img style="margin-top:-30px"  src="/img/qrcode.JPG" alt="teste" />
      </div>
    </div>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PreviewsAds",
  props: ["anuncio"],
  computed:{
    showQr(){
      return this.$store.state.ads.newAds.showQr
      || this.$store.state.ads.editedAds.showQr
      }
  }
};
</script>

<style scoped>
.prev {
    position: relative;
  width: 1080px;
  height: 1920px;
  zoom: .3;
}
video,
img {
  width: 1080px;
  height: 1920px;
}
.imgPrev{
    position:absolute;
    bottom: 300px;
    right: 150px;
    background: #fff;    
    width: 280px;
    height: 280px;
    border-radius: 50px;
    border: solid 10px #000;
    overflow: hidden;
    text-align: center;
}
.imgPrev img{
    
width: 180px;
height: 180px;
bottom: 0;
}
.leiamais{
  font-family: arial;
  text-align: center;
padding-bottom: 20px;
  font-weight: 900;
  font-size: 25pt;
  font-family: monospace;
}
</style>